<template>
  <div>
    <h2 class="text-center fw-bolder mt-4 title">Vremenske statistike</h2>
    <Statistics />
  </div>
</template>

<script>
import Statistics from '../components/Statistics/Statistics.vue';
export default {
    name: 'Stats',
    components: {
        Statistics
    }
}
</script>

<style scoped>
.title {
    font-size: 37px;
}
</style>