export const TableSnow = [
    {
        season: "2009/10",
        snowDays: 82,
        maxSnowLayerHeight: 68,
        maxSnowLayerDate: "11. februar",
        maxFreshSnowHeight: 29,
        maxFreshSnowDate: "10. marec",
        accumulatedSnow: 297,
        snowLayerFirstDate: "4. november",
        snowLayerFirstValue: 1,
        snowLayerLastDate: "18. marec",
        snowLayerLastValue: 8 
    },
    {
        season: "2010/11",
        snowDays: 79,
        maxSnowLayerHeight: 62,
        maxSnowLayerDate: "5. december",
        maxFreshSnowHeight: 42,
        maxFreshSnowDate: "29. november",
        accumulatedSnow: 183,
        snowLayerFirstDate: "27. november",
        snowLayerFirstValue: 25,
        snowLayerLastDate: "7. marec",
        snowLayerLastValue: 1 
    },
    {
        season: "2011/12",
        snowDays: 50,
        maxSnowLayerHeight: 36,
        maxSnowLayerDate: "13. februar",
        maxFreshSnowHeight: 14,
        maxFreshSnowDate: "7. februar",
        accumulatedSnow: 113,
        snowLayerFirstDate: "8. oktober",
        snowLayerFirstValue: 1,
        snowLayerLastDate: "1. marec",
        snowLayerLastValue: 4 
    },
    {
        season: "2012/13",
        snowDays: 97,
        maxSnowLayerHeight: 118,
        maxSnowLayerDate: "24. februar",
        maxFreshSnowHeight: 41,
        maxFreshSnowDate: "24. februar",
        accumulatedSnow: 492,
        snowLayerFirstDate: "28. oktober",
        snowLayerFirstValue: 4,
        snowLayerLastDate: "3. april",
        snowLayerLastValue: 10 
    },
    {
        season: "2013/14",
        snowDays: 20,
        maxSnowLayerHeight: 32,
        maxSnowLayerDate: "31. januar",
        maxFreshSnowHeight: 30,
        maxFreshSnowDate: "25. januar",
        accumulatedSnow: 173,
        snowLayerFirstDate: "1. december",
        snowLayerFirstValue: 4,
        snowLayerLastDate: "13. februar",
        snowLayerLastValue: 3 
    },
    {
        season: "2014/15",
        snowDays: 51,
        maxSnowLayerHeight: 60,
        maxSnowLayerDate: "7. februar",
        maxFreshSnowHeight: 27,
        maxFreshSnowDate: "28. december",
        accumulatedSnow: 136,
        snowLayerFirstDate: "26. december",
        snowLayerFirstValue: 2,
        snowLayerLastDate: "5. marec",
        snowLayerLastValue: 2 
    },
    {
        season: "2015/16",
        snowDays: 44,
        maxSnowLayerHeight: 26,
        maxSnowLayerDate: "7. januar",
        maxFreshSnowHeight: 25,
        maxFreshSnowDate: "13. februar",
        accumulatedSnow: 123,
        snowLayerFirstDate: "22. november",
        snowLayerFirstValue: 20,
        snowLayerLastDate: "29. april",
        snowLayerLastValue: 4 
    },
 {
        season: "2016/17",
        snowDays: 38,
        maxSnowLayerHeight: 33,
        maxSnowLayerDate: "18. januar",
        maxFreshSnowHeight: 16,
        maxFreshSnowDate: "14. januar",
        accumulatedSnow: 57,
        snowLayerFirstDate: "12. november",
        snowLayerFirstValue: 1,
        snowLayerLastDate: "1. marec",
        snowLayerLastValue: 1 
    },
    {
        season: "2017/18",
        snowDays: 75,
        maxSnowLayerHeight: 84,
        maxSnowLayerDate: "27. februar",
        maxFreshSnowHeight: 49,
        maxFreshSnowDate: "3. februar",
        accumulatedSnow: 288,
        snowLayerFirstDate: "13. november",
        snowLayerFirstValue: 6,
        snowLayerLastDate: "24. marec",
        snowLayerLastValue: 1 
    },
    {
        season: "2018/19",
        snowDays: 30,
        maxSnowLayerHeight: 15,
        maxSnowLayerDate: "25. januar",
        maxFreshSnowHeight: 13,
        maxFreshSnowDate: "20. november",
        accumulatedSnow: 58,
        snowLayerFirstDate: "20. november",
        snowLayerFirstValue: 13,
        snowLayerLastDate: "12. marec",
        snowLayerLastValue: 2 
    },
    {
        season: "2019/20",
        snowDays: 13,
        maxSnowLayerHeight: 17,
        maxSnowLayerDate: "26. marec",
        maxFreshSnowHeight: 14,
        maxFreshSnowDate: "25. marec",
        accumulatedSnow: 45,
        snowLayerFirstDate: "13. december",
        snowLayerFirstValue: 6,
        snowLayerLastDate: "31. marec",
        snowLayerLastValue: 1 
    },
    {
        season: "2020/21",
        snowDays: 48,
        maxSnowLayerHeight: 36,
        maxSnowLayerDate: "3. december",
        maxFreshSnowHeight: 36,
        maxFreshSnowDate: "3. december",
        accumulatedSnow: 116,
        snowLayerFirstDate: "3. december",
        snowLayerFirstValue: 36,
        snowLayerLastDate: "14. april",
        snowLayerLastValue: 2 
    },
]

export default TableSnow;