<template>
  <div class="container mt-4">
      <div class="row">
          <div class="col-12 col-xl-12">
            <h4 class="fw-bolder mb-4">Pretekle zime na vremenski postaji Prigorica</h4>
            <Table />
          </div>
      </div>
  </div>
</template>

<script>
import Table from './Table.vue'

export default {
    name: 'Statistics',
    components: {
        Table
    }
}
</script>

<style>

</style>