<template>
  <div class="table-responsive text-center">
    <table class="table table-bordered border-secondary caption-top">
      <thead>
        <tr class="fw-bolder">
          <td class="align-top">Sezona</td>
          <td>Število dni s snežno odejo</td>
          <td>Največja višina snežne odeje</td>
          <td>Največja višina novozapadlega snega</td>
          <td>Skupna višina zapadlega snega</td>
          <td>Prvi dan s snežno odejo</td>
          <td>Zadnji dan s snežno odejo</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in SnowData" :key="item">
          <td class="align-middle fw-bolder">{{ item.season }}</td>
          <td class="align-middle colorized fw-bolder">{{ item.snowDays }}</td>
          <td class="align-middle"><span class="colorized fw-bolder">{{ item.maxSnowLayerHeight }} cm</span> <br /> ({{ item.maxSnowLayerDate }})</td>
          <td class="align-middle"><span class="colorized fw-bolder">{{ item.maxFreshSnowHeight }} cm</span> <br /> ({{ item.maxFreshSnowDate }})</td>
          <td class="align-middle colorized fw-bolder">{{ item.accumulatedSnow }} cm</td>
          <td class="align-middle"><span class="colorized fw-bolder">{{ item.snowLayerFirstDate }}</span> <br /> ({{ item.snowLayerFirstValue }} cm)</td>
          <td class="align-middle"><span class="colorized fw-bolder">{{ item.snowLayerLastDate }}</span> <br /> ({{ item.snowLayerLastValue }} cm)</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SnowData from '../../Utils/Snow/Snow'

export default {
    name: 'Table',
    data() {
        return {
            SnowData: SnowData
        };
    }
};
</script>

<style scoped>
table th, td {
    border-color: black;
    border-width: 1.5px 1.5px 1.5px 1.5px;
}

.colorized {
    color: #00b0f0;
}
</style>